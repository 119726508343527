
import { defineComponent, PropType } from 'vue';
import { CountTo } from 'vue3-count-to';

import { RevenueDailyResponse } from '@/services/api';

export default defineComponent({
  components: {
    CountTo
  },
  props: {
    revenueDaily: {
      type: Object as PropType<RevenueDailyResponse['data']>,
      default: () => ({})
    }
  }
});
